export default {
	white: '#000000',
	brandGreen: '#000000',
	brandYellow: '#000000',
	brandBlue: '#000000',
	brandRed: '#000000',
	brandPurple: '#ddc081',

	// Pillar colors
	purple: '#000000',
	textLight: '#ddc081',
	headerLight: '#ddc081',
	neonGreen: '#ddc081',
	grey: '#000000',
	totalBlack: '#000000',
	secondaryButton: 'rgba(0,0,0,0.6)',
	border: '#000000',

	// button palette
	buttonDark: '#ddc081',
	buttonLight: '#ddc081',

	buttonLightDisabled: 'rgba(0,0,0,0.6)',

	// lightMode
	// fonts:
	light1: '#ddc081',
	light2: '#ddc081',
	light3: '#ddc081',
	// UI (dark > light):
	light4: '#CACAF1',
	light5: '#E8E7FD',
	light6: '#F3F3FE',
	light7: '#FCFCFE',
	light8: '#F7DADD',
	// shadows
	lightShadow1: 'rgba(0,0,0,0.6)',

	// darkMode
	// UI (dark > light):
	dark1: '#0E0D14',
	dark2: '#14131E',
	dark3: '#1C1A28',
	dark4: '#262439',
	// fonts:
	dark5: '#ddc081',
	dark6: '#ddc081',
	// accents:
	dark7: '#3A3B74',
	dark8: '#6865A3',
	dark9: '#E47C87',
	// shadows:
	darkShadow1: 'rgba(0,0,0,0.6)',
};
